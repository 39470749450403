$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.memorabiliaList {
  padding-left: 102px;

  .headerImg {
    width: 98px;
    height: 98px;
    transform: translate(-50%, 0);
    @include bg-img(100% 100%);
  }

  .main {
    border-left: 6px solid $purple;

    .item {
      position: relative;
      padding-left: 130px;

      &::before {
        position: absolute;
        top: 31px;
        left: 0;
        border-bottom: 2px solid $purple;
        width: 129px;
      }
    }
  }

  .footerImg {
    width: 117px;
    height: 88px;
    transform: translate(-50%, 0);
    @include bg-img(100% 100%);
  }
}

.itemWrapper {
  & + .itemWrapper {
    margin-top: 28px;
  }
}