@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1593175895212'); /* IE9 */
  src: url('iconfont.eot?t=1593175895212#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAREAAsAAAAACUwAAAP2AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDTgqFCIQaATYCJAMgCxIABCAFhG0HehsACBGVnDvIfgy27eQylLNWGOUQWCu4L/fyi+7u3nj4b7//7TMz9775rknAk+rqJBLNPOOJiJcAJbEInebpa0Lr+8u/fPn7Twg3VKnK6k5l5ApgdXAZ76W7MpA6VJlU9OY0f6xuQAVIJvzjH97FPSTbWcopSsfxMlyECmALWC8OVTOnBedTYjEJaMDmWjYmUC6UtD3vFWiMzSVrQSrqtRDPXjohcMGf8TqBXot2i3Op2VKoCn5eIG6XleRBLTiUDmao5rbh1gLxCho1DVK7AF66348tCAs2SJoM/NLzV1MK4dQq/P+jZ//PXDQIUO7Ph9FdZOxGIW42Bi7LobBb1rt4WnEIVZVYjRu8uq3bBY7qWnxVr/iHR9EREtECN/Qz7OVih1VKSGE1FE3GKehVjdsgIOlf9ffROEMABGkNroLyCQao5pZh9OED/uOmY66UZCG+lPoRny+0s7O1xbj59FR6dia7uFCcn5eivOZMVpFCK8e8rP7x0mFHmbbDQaohrkhvqSou0XeSIYdCjaM4skg7bOnMKynWdVqGczYHbV1siHSWUKOTQ05yDGKFfniqw6Fa3qnchWyWxdV6jPEGJyedU7FufNLoWFSoMUwN4wq3WaLH+rbDvFHcHP3zjebRnLHP0ZlMa+dxSfaLkfIj8M7BVvrujkbWendvRUJqFGn3nTVTiZaWkJKxlpaVlJQp2BOXbqY51df9t/4bP/s+ftmIBLbwuQ7wwwZdw1wMZjPx9LKwBoNLuAvsbkIYaa4DxAHX4z89XV8n7jJ3cnVFPLQ8s8LxBvENk3uKFI80waFLsiDagWNPB8kkmdvXdSISESLaiajWa6fqZvvPSDNkQZQT4Wh4CroLeRHFOc4FmkTB3EXqEiWQECWB9I7qTeXn1PM/5W/0vO+LzxFFv1YeDfBlfliBRHehwPaKr1tJVv4vlMS5slRzZy3z8ZQDQKxMe9/MUOLPoP5XVBj1t0/TBLmVUE1kkHQsQFYtIQt2NzT67INWdRJ67dLc3WeM2IrSg52+EgjDviEZ9BGyYT/Igv2FxqR/aA0Hgl4XYuyRfVai7PqsoFH0YD8hOR5JuvXatz9iWHojebVjv6LMKQ1VXpbLDY4o+7hifgu1KgEJD3BJ3oZ9zzAJt+g0j6rTuSio6Ztyx8Pq2lkCGQp5wPoEEoeNyKxX15Wff4SCRc+QjqEL0VdIzNLBQSVXEpCNYSQNPZejZ2+CmlKUnkUEG4ALfKgXBQam5pNayFG5uEVuclawZUQ15Mc3Da/0HujFP1sjRY4STbTRiRo9mvu9+ayO+UxmDE5mvHFJOLIEx+AXvr/Up6zW22MmV0gf8tdJClGPD6IZO5NWKwAA') format('woff2'),
  url('iconfont.woff?t=1593175895212') format('woff'),
  url('iconfont.ttf?t=1593175895212') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('iconfont.svg?t=1593175895212#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconplay1:before {
  content: "\e617";
}

.iconbofang1:before {
  content: "\e627";
}

.iconfanhui1:before {
  content: "\ee600";
}

.icongengduo:before {
  content: "\e600";
}

.iconcli-angle-left:before {
  content: "\ce624";
}

.iconcli-angle-right:before {
  content: "\ce625";
}

.iconzhankai:before {
  content: "\e601";
}

