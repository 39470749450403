$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.nav {
  display: flex;
  justify-content: space-between;
  max-width: 1018px;
  margin: 0 auto;
  color: $purple;

  .left {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
    margin-left: -23px;
    padding-left: 106px;
  }

  .logo {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100px;
  }

  .title {
    font-size: 21px;
  }

  .subTitle {
    font-size: 15px;
    font-weight: bold;
  }
}

.mobileMenu {
  display: none;
}

.bannerWrapper {
  position: relative;
}

.searchWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.5);
}

/* 移动端 布局 */
@media (max-width: 750px) {
  .nav {
    .left {
      margin-left: 20px;
    }
  }

  .pcMenu {
    display: none;
  }

  .mobileMenu {
    display: block;
  }
}