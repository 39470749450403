$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.mobileMenu {
  position: relative;

  .moreBtn {
    background: $purple;
    border: none;
    width: 60px;
    height: 60px;
    font-size: 30px;

    &:hover, &:active, &:focus {
      background: $purple;
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    z-index: 1;

    &.collapsed {
      display: block;
    }

    :global {
      .ant-menu {
        background: $purple;
        color: #fff;
      }

      .ant-menu-inline .ant-menu-item {
        padding: 0 40px;
        outline: none;

        &:hover {
          color: #fff;
        }

        &:active {
          background: #fff;
          color: $purple;
        }

        &.ant-menu-item-selected {
          background: #fff;
          color: $purple;
        }
      }

      .ant-menu-submenu-title {
        color: #fff;
      }

      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #fff !important;
      }

      .ant-menu-vertical .ant-menu-item::after,
      .ant-menu-vertical-left .ant-menu-item::after,
      .ant-menu-vertical-right .ant-menu-item::after,
      .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid $purple;
      }
    }
  }
}