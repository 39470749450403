$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.sidebar {
  border: 1px solid $purple;
  color: $purple;
}

.line {
  width: 190px;
  border-bottom: 1px solid $purple;
  margin: 20px auto;
}

.nav {
  width: 190px;
  margin: 0 auto;

  .level1 {
    margin-bottom: 30px;
  }

  .level1Text {
    display: flex;
    height: 50px;
    border-radius: 6px;
    border: 1px solid $purple;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    &.active {
      background: $purple;
      color: #fff;
    }
  }

  .level2 {
    height: 26px;
    margin-top: 14px;
    border: 1px solid $purple;

    &.active {
      background: $purple;
      color: #fff;
    }
  }
}

.img {
  width: 100%;
  height: 232px;
  @include bg-img;
}

.canClick {
  cursor: pointer;
}