$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.managementItem {
  display: flex;
  height: 146px;
  border: 1px solid $purple;
  align-items: center;
  color: $purple;
  cursor: pointer;
}

.date {
  display: flex;
  flex-flow: column;
  border: 1px solid $purple;
  width: 85px;
  height: 85px;
  margin-left: 18px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;

  .line {
    border-bottom: 1px solid $purple;
    width: 90%;
    margin: 0 auto;
  }
}

.main {
  flex: 1 1;
  padding: 0 30px 0 20px;
  max-height: calc(100% - 36px);
  overflow: hidden;
  text-align: left;

  .title {
    @include text-overflow-1line(20em);

    font-size: 18px;
  }

  .content {
    @include text-overflow-4line;
    min-height: 80px;

    font-size: 13px;
  }
}

.option {
  display: flex;
  border-left: 1px solid $purple;
  padding: 0 20px;
  height: 60%;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 40px;
  }
}