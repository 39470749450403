$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.contactUs {
  display: flex;
  justify-content: center;
  padding: 30px 0 60px;
  background: $purple;
  color: #fff;

  .title {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: left;
  }

  .left {
    width: 736px;
  }

  .right {
    margin-left: 38px;
  }

  .code {
    width: 200px;
    min-height: 195px;
    margin-bottom: 30px;

    img {
      width: 100%;
    }
  }

  .codeMsg {
    text-align: center;
  }
}

/* 移动端 布局 */
@media (max-width: 750px) {
  .contactUs {
    padding: 30px 30px 60px;

    .title {
      font-size: 22px;
    }

    .contactUsComp {
      font-size: 12px;

      & > div {
        width: 23%;
        height: auto;
        padding-top: 30px;
        padding-bottom: 10px;

        p {
          word-break: break-all;
        }
      }
    }

    .left {
      width: 86%;
    }

    .code {
      width: 100%;
      min-height: auto;
    }

    .codeMsg {
      font-size: 12px;
    }
  }
}