$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.newsItem {
  display: flex;
  width: 468px;

  .title {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #cfcfcf;
    justify-content: space-between;
    align-content: center;
    font-size: 16px;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .time {
    margin-right: 20px;
  }

  &.showDetail {
    .img {
      width: 182px;
      min-width: 182px;
      height: 158px;
      margin-right: 40px;
      overflow: hidden;
      @include bg-img;
    }

    .title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 0;
        bottom: 0;
        border-left: 2px solid $purple;
      }
    }

    .time {
      display: none;
    }

    .detail {
      height: 130px;
      padding: 5px 0;
      line-height: 20px;
      border-bottom: 1px solid #cfcfcf;
      overflow: hidden;
      text-align: left;
      font-size: 12px;
      color: #000;
    }
  }

  &.line {
    .title {
      line-height: 35px;
    }
  }
}

/* 移动端 布局 */
@media (max-width: 750px) {
  .newsItem {
    width: 100%;
  }

  .newsItem:nth-child(1) {
    .title::before {
      display: none;
    }
  }

  .newsItem:nth-child(2) {
    .img {
      display: none;
    }

    .msg {
      width: 100%;
    }

    .title::before {
      display: none;
    }

    .time {
      display: block;
    }

    .detail {
      display: none;
    }
  }
}