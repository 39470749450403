$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.eventsAxiosItem {
  display: inline-block;
  text-align: center;
  flex-basis: 96px;

  &:nth-of-type(2n) {
    .line {
      height: 175px;
    }
  }
}

.eventsAxiosItem + .eventsAxiosItem {
  margin-left: 18px;
}

.date {
  display: inline-block;
  padding: 0 8px;
  border-radius: 2px;
  background: $purple;
  color: #fff;
}

.line {
  position: relative;
  margin: 15px auto 0;
  width: 2px;
  height: 44px;
  background: $purple;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: $purple;
  }
}

.title {
  width: 150px;
  text-align: center;
  margin: 0 -20px 5px;
}

.img {
  margin: 0 auto;
  width: 96px;
  height: 96px;
  border-radius: 50%;

  @include bg-img;
}

.icon {
  font-size: 20px;
  cursor: pointer;
}