$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.newStationItem {
  & + .newStationItem {
    padding-top: 25px;
  }

  &::before, &::after {
    display: table;
    content: '';
    clear: both;
  }

  .date {
    float: left;
    width: 88px;
    height: 98px;
    border: 1px solid $purple;
    margin-right: 30px;
    padding-top: 20px;
    color: $purple;

    .year {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 1em;
    }

    .monthDay {
      font-size: 18px;
      line-height: 1em;
    }
  }

  .itemMain {
    border-bottom: 1px solid $purple;
    padding-bottom: 20px;
    overflow: hidden;
    text-align: left;
    color: $purple;

    .title {
      @include text-overflow-1line;

      font-size: 26px;
    }

    .content {
      &::before, &::after {
        content: '';
        display: table;
        clear: both;
      }
    }

    .detail {
      @include text-overflow-4line;

      font-size: 16px;

      :global {
        img {
          float: right;
          width: 212px;
          height: 155px;
          margin-left: 30px;
        }
      }
    }

    .img {
      float: right;
      width: 212px;
      height: 155px;
      margin-left: 30px;
    }

    .footer {
      padding-top: 15px;
      text-align: center;
    }
  }
}