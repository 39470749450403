$purple: #582354;

$black: #19171a;

$homeWidth: 975px;

$contentWidth: 1018px;

@mixin bg-img($size: cover) {
  background-position: center center;
  background-size: $size;
  background-repeat: no-repeat;
}

@mixin right-content {
  flex: 1;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $purple;
  flex-flow: column;

  > :global(:first-child) {
   flex: 1;
  }
}

@mixin text-overflow-4line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@mixin text-overflow-1line($max-width: 10em) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin antd-btn-reset {
  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: $purple;
    color: #fff;
    border: none;
  }
}
.newsListContainer {
  width: $homeWidth;
  margin: 48px auto 0;
}
.newsList {
  display: flex;
  width: 100%;
  flex-flow: wrap;
}

.newsItem:nth-child(2n) {
  margin-left: 38px;
}

.footer {
  padding: 10px 44px;
  text-align: right;
  cursor: pointer;

  .right {
    display: inline-block;
    position: relative;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: -28px;
    transform: translate(0, -50%);
    line-height: 1em;
  }
}

/* 移动端 布局 */
@media (max-width: 750px) {
  .newsListContainer {
    width: 100%;
  }

  .newsList {
    padding: 0 20px;
  }

  .newsItem:nth-child(2n) {
    margin-left: 0;
  }

  .footer {
    text-align: center;
  }
}